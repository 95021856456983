$('.h-events__item').on('mouseover', function () {
    const image = $(this).attr('data-image');
    const imagePath = `/storage/app/media/organizacja-wydarzen/${image}.jpg`;
    if(fileExists(imagePath)){
        $('.h-events__photos').fadeOut('fast', function () {
            $(this).css('background-image', `url(${imagePath})`).fadeIn('fast');
        });
    }

});
function fileExists(url) {
    if(url){
        var req = new XMLHttpRequest();
        req.open('GET', url, false);
        req.send();
        return req.status==200;
    } else {
        return false;
    }
}
